var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mt-15" },
    [
      this.$store.state.authenticate.UserLogged
        ? _c("v-layout", [
            _c("h2", { staticClass: "u-green-text mb-0" }, [
              _vm._v(_vm._s(_vm.$t("MyAppointments"))),
            ]),
          ])
        : _vm._e(),
      this.$store.state.authenticate.UserLogged
        ? _c(
            "div",
            [
              _c(
                "v-layout",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      class: { "white--text": _vm.activeAppointments },
                      attrs: { color: _vm.getBtnColor(true) },
                      on: {
                        click: function ($event) {
                          return _vm.changeFilterAppointmentStatus(true)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Active")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      class: { "white--text": !_vm.activeAppointments },
                      attrs: { color: _vm.getBtnColor(false) },
                      on: {
                        click: function ($event) {
                          return _vm.changeFilterAppointmentStatus(false)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Completed")) + " ")]
                  ),
                ],
                1
              ),
              _c("v-layout", [
                this.$store.state.appointment.myAppointmentList.length === 0 &&
                !this.$store.state.appointment.isLoading
                  ? _c("p", { staticClass: "u-blue-text mt-4" }, [
                      _c("i", { staticClass: "mdi mdi-information" }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("YouHaveNot")) +
                          " " +
                          _vm._s(_vm.$t("Appointments")) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "v-layout",
                [
                  _c("v-text-field", {
                    attrs: {
                      "append-icon": "mdi-magnify",
                      label: _vm.getColumText("Search"),
                      "single-line": "",
                      "hide-details": "",
                      "no-data-text": this.$t("NoAppointmentsAvailable"),
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-layout",
                { staticClass: "mt-5", attrs: { "justify-end": "" } },
                [
                  !_vm.activeAppointments
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            depressed: "",
                            disabled: _vm.selected.length == 0,
                            small: "",
                          },
                          on: { click: _vm.CreateZipRO },
                        },
                        [
                          _c("i", { staticClass: "mdi mdi-database" }),
                          _vm._v(" " + _vm._s(_vm.$t("ZIPPEDRO")) + " "),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-layout",
                [
                  _c("v-data-table", {
                    attrs: {
                      loading: this.$store.state.appointment.isLoading,
                      headers: _vm.getHeaders(),
                      items: this.$store.state.appointment.myAppointmentList,
                      search: _vm.search,
                      "show-select": !_vm.activeAppointments,
                      "loading-text": "Loading appointments",
                      "sort-by": ["id"],
                      "sort-desc": [true],
                    },
                    scopedSlots: _vm._u(
                      [
                        this.$store.state.appointment.isLoading
                          ? {
                              key: "body",
                              fn: function () {
                                return [
                                  _c("tbody", [
                                    _c(
                                      "tr",
                                      {
                                        staticClass:
                                          "v-data-table__empty-wrapper",
                                      },
                                      [
                                        _c("td", { attrs: { colspan: "11" } }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("LoadingAppointments")
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                        {
                          key: "header.data-table-select",
                          fn: function () {
                            return undefined
                          },
                          proxy: true,
                        },
                        {
                          key: "item.data-table-select",
                          fn: function (item) {
                            return [
                              _c("v-checkbox", {
                                attrs: { value: item.item.id },
                                model: {
                                  value: _vm.selected,
                                  callback: function ($$v) {
                                    _vm.selected = $$v
                                  },
                                  expression: "selected",
                                },
                              }),
                            ]
                          },
                        },
                        {
                          key: "item.total",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("currency")(item.total)) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.status",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    "x-small": "",
                                    color: _vm.getColor(item.status),
                                    dark: "",
                                  },
                                },
                                [_vm._v(_vm._s(item.status))]
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.map",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-small": "",
                                    disabled: !item.isTracking,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openMapView(item)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "mdi mdi-map-marker-outline",
                                  }),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.text",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: { "x-small": "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openChatView(item)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "mdi mdi-forum" })]
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.call",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "a",
                                {
                                  attrs: { href: "tel:" + item.mechanicPhone },
                                },
                                [
                                  _c("v-btn", { attrs: { "x-small": "" } }, [
                                    _c("i", {
                                      staticClass: "mdi mdi-phone black--text",
                                    }),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.edit",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: { "x-small": "" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.editMyAppointment(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("i", {
                                                  class: [
                                                    _vm.isAppointmentEditable(
                                                      item
                                                    )
                                                      ? "mdi mdi-square-edit-outline"
                                                      : "mdi mdi-eye",
                                                  ],
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.isAppointmentEditable(item)
                                          ? _vm.$t("ApproveTooltip")
                                          : _vm.$t("ViewTooltip")
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.cancel",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-small": "",
                                    disabled:
                                      item.status === "Cancelled"
                                        ? true
                                        : false,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancelAppointment(item)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "mdi mdi-close" })]
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.ro",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: { "x-small": "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.DisplayROPdf(item.id)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "mdi mdi-eye" })]
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.review",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-small": "",
                                    disabled: !item.hasPendingReview,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.MakeReview(item.id)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "mdi mdi-dots-horizontal-circle-outline",
                                  }),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.data-table-expand",
                          fn: function (ref) {
                            var item = ref.item
                            var isExpanded = ref.isExpanded
                            var expand = ref.expand
                            return [
                              item.photos.length > 0 && !isExpanded
                                ? _c(
                                    "v-icon",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return expand(true)
                                        },
                                      },
                                    },
                                    [_vm._v(" mdi-chevron-down ")]
                                  )
                                : _vm._e(),
                              item.photos.length > 0 && isExpanded
                                ? _c(
                                    "v-icon",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return expand(false)
                                        },
                                      },
                                    },
                                    [_vm._v(" mdi-chevron-up ")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "expanded-item",
                          fn: function (ref) {
                            var headers = ref.headers
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                { attrs: { colspan: headers.length } },
                                [
                                  _c(
                                    "v-carousel",
                                    {
                                      staticClass: "my-4",
                                      staticStyle: { width: "500px" },
                                      attrs: {
                                        height: "300px",
                                        "hide-delimiter-background": "",
                                      },
                                    },
                                    _vm._l(item.photos, function (photo, i) {
                                      return _c("v-carousel-item", {
                                        key: i,
                                        attrs: {
                                          src: photo,
                                          "reverse-transition":
                                            "fade-transition",
                                          transition: "fade-transition",
                                          height: "100%",
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !this.$store.state.authenticate.UserLogged
        ? _c(
            "v-row",
            [
              _c("v-alert", { attrs: { text: "", type: "info" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "WeAreSorryPermissionIsNeededToAccessThisPagePleaseLogin"
                      )
                    ) +
                    " "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "490" },
          model: {
            value: this.$store.state.appointment.isEditAppointmentVisible,
            callback: function ($$v) {
              _vm.$set(
                this.$store.state.appointment,
                "isEditAppointmentVisible",
                $$v
              )
            },
            expression:
              "this.$store.state.appointment.isEditAppointmentVisible",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "u-blue-text" }, [
                _c("p", { staticStyle: { width: "100%" } }, [
                  _vm.matchedWindowsStep(1)
                    ? _c("i", {
                        class: [
                          _vm.isAppointmentEditable(null)
                            ? "mdi mdi-square-edit-outline"
                            : "mdi mdi-eye",
                        ],
                      })
                    : _vm._e(),
                  _vm.matchedWindowsStep(2)
                    ? _c("i", {
                        staticClass: "mdi mdi-chevron-left",
                        on: {
                          click: function ($event) {
                            return _vm.setFirstStep()
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.matchedWindowsStep(1)
                          ? _vm.isAppointmentEditable(null)
                            ? _vm.$t("EditAppointment")
                            : _vm.$t("ViewAppointment")
                          : _vm.$t("ConfirmAppointment")
                      ) +
                      " "
                  ),
                  _c("i", {
                    staticClass: "mdi mdi-close-circle-outline float-right",
                    staticStyle: { cursor: "pointer" },
                    on: { click: _vm.closeEditDialog },
                  }),
                ]),
              ]),
              _c("v-card-text", [_c("edit-appointment")], 1),
              _c("v-card-actions"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.smsDialogVisible,
            callback: function ($$v) {
              _vm.smsDialogVisible = $$v
            },
            expression: "smsDialogVisible",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "u-blue-text" }, [
                _c("p", { staticStyle: { width: "100%" } }, [
                  _c("i", { staticClass: "mdi mdi-forum" }),
                  _vm._v(" " + _vm._s(_vm.$t("SendMessage")) + " "),
                  _c("i", {
                    staticClass: "mdi mdi-close-circle-outline float-right",
                    on: { click: _vm.closeSendMessageDialog },
                  }),
                ]),
              ]),
              _c(
                "v-card-text",
                [
                  _c("sms-sender", {
                    attrs: {
                      mechanicName: _vm.mechanicName,
                      tupletId: _vm.tupletId,
                      userId: _vm.userId,
                      messages: _vm.messagesList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "350", persistent: "" },
          model: {
            value: _vm.cancelDialog,
            callback: function ($$v) {
              _vm.cancelDialog = $$v
            },
            expression: "cancelDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "u-blue-text mb-0" }, [
                _c(
                  "p",
                  {
                    staticStyle: {
                      width: "100%",
                      "font-family": "rubik",
                      "font-weight": "600",
                      "margin-bottom": "5px",
                    },
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("Confirmation")) + " "),
                    _c("i", {
                      staticClass: "mdi mdi-close-circle-outline float-right",
                      on: {
                        click: function ($event) {
                          _vm.cancelDialog = false
                        },
                      },
                    }),
                  ]
                ),
              ]),
              _c("v-card-text", [
                _c(
                  "span",
                  {
                    staticClass: "u-blue-text sub-title-1",
                    staticStyle: {
                      "font-size": "17px",
                      "lin-height": "0.95",
                      "letter-spacing": "0.7px",
                      "font-family": "rubik",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("AreYouSureYouWantToCancelThisAppointment")
                        ) +
                        " " +
                        _vm._s(this.declineMessageWithFee) +
                        " "
                    ),
                  ]
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green", outlined: "" },
                      on: {
                        click: function ($event) {
                          _vm.cancelDialog = false
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("NoDoNotCancel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text u-background-primary",
                      attrs: { loading: _vm.cancelLoading },
                      on: { click: _vm.confirmCancelAppointment },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("YesCancel")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "700" },
          model: {
            value: _vm.mapDialog,
            callback: function ($$v) {
              _vm.mapDialog = $$v
            },
            expression: "mapDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "u-blue-text mb-0" }, [
                _c("p", { staticStyle: { width: "100%" } }, [
                  _c("i", { staticClass: "mdi mdi-information" }),
                  _vm._v(" " + _vm._s(_vm.$t("Map")) + " "),
                  _c("i", {
                    staticClass: "mdi mdi-close-circle-outline float-right",
                    on: {
                      click: function ($event) {
                        _vm.mapDialog = false
                      },
                    },
                  }),
                ]),
              ]),
              _c("v-card-text", [
                _c("iframe", {
                  ref: "mapComponent",
                  staticStyle: { width: "100%", height: "480px" },
                  attrs: {
                    frameborder: "0",
                    src: _vm.mapAddress,
                    allowfullscreen: "",
                  },
                }),
              ]),
              _c("v-card-actions"),
            ],
            1
          ),
        ],
        1
      ),
      _c("view-ro-pdf", { attrs: { roUrl: _vm.roUrl } }),
      _c("review", { attrs: { dialog: _vm.displayReviewPage } }),
      _c("zip-ro-email-entry", {
        attrs: {
          appointmentIds: _vm.selected,
          emailAddress: _vm.userEmailAddress,
          modalVisibility: _vm.zipRoVisible,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }